import Cookies from "universal-cookie";
import axios from "axios";
import AppConstants, { SOCKET_IO_MESSAGES, tilesServer } from "./AppConstants";
import { getActiveTeamId } from "./Teams";
import { pushMeasurementToSocketRoom } from "./AeroFarm/SocketIo";
import { pushAnnotationToSocketRoom } from "./AeroCapture/SocketIo";
import { Popup } from "./ReusableComponents/reusableFunctions";


const cookie = new Cookies(),
  URL =
    process.env.REACT_APP_CLIENT_PROTOCOL +
    "://" +
    process.env.REACT_APP_CLIENT_HOST +
    (process.env.REACT_APP_CLIENT_PORT
      ? process.env.REACT_APP_CLIENT_PORT
      : ""),
  PROXY = "https://cors-anywhere.herokuapp.com"
export const SERVER =
  process.env.REACT_APP_SERVER_PROTOCOL +
  "://" +
  process.env.REACT_APP_SERVER_HOST +
  (process.env.REACT_APP_SERVER_PORT
    ? process.env.REACT_APP_SERVER_PORT
    : ""),
  GET_USER_CREDITS_DETAILS_ADMIN = `${SERVER}/adminTool/getUserCreditsByAdmin`,
  GET_USER_STORAGE = `${SERVER}/Aeromegh/subscription/getUserStorage`,
  UPDATE_USER_CREDITS_DETAILS_ADMIN = `${SERVER}/adminTool/updateUserCreditsByAdmin`,

  SESSION_CHECK = `${SERVER}/sessionCheck`,
  GET_USERS = `${SERVER}/users`,
  GET_COUNTRIES = `${SERVER}/countries`,
  GET_STATES = `${SERVER}/states`,
  USER_SEARCH = `${SERVER}/user`,
  USER_EDIT = `${SERVER}/user`,
  ORGANIZATION_DETAILS = `${SERVER}/organization`,
  GROUPS_AND_ORGANIZATIONS = `${SERVER}/groupsAndOrganizations`,
  GET_REGISTRATION_STATUS = `${SERVER}/getRegistrationStatus`,
  GET_PLAN = `${SERVER}/Aeromegh/remoteMission/checkPlanExists`,
  GET_PROJECT = `${SERVER}/Aeromegh/remoteMission/checkProjectExists`,
  ADD_NEW_PROJECT = `${SERVER}/AeroMegh/AeroCapture/addProject`,
  ADD_PLAN = `${SERVER}/Aeromegh/remoteMission/addPlan`,
  IS_PLAN_EXIST = `${SERVER}/Aeromegh/remoteMission/checkPlanExists`,
  USER_STATS = `${SERVER}/AeroMegh/stats/users`,
  UPDATE_PASSWORD = `${SERVER}/updatePassword`,
  GET_INVOICE = `${SERVER}/AeroMegh/invoices`,
  ADMIN_USER_INVOICES = `${SERVER}/AeroMegh/admin/invoices`,
  MANUFACTURER = "3873ca27-15de-4255-b256-a0c045d9282a",
  GETANNOTATION = `${SERVER}/AeroMegh/getMLAnnotation`,
  SAVEANNOTATION = `${SERVER}/AeroMegh/saveMLAnnotation`,
  TASK_START = `${PROXY}/${SERVER}/AeroMegh/ml_tool/tasks/startTaskTraining`,
  CUSTOMER = "8b28f377-c02f-4f3c-8bd7-f4794c066546",
  ADMIN_SEND_INVOICE_REMINDER = `${SERVER}/AeroMegh/admin/sendInvoiceReminder`,
  CANCEL_SUBSCRIPTION = `${SERVER}/cancelSubscription`,
  UPDATE_SUBSCRIPTION = `${SERVER}/AeroMegh/user/pgSubscription`,
  ADD_TASK = `${SERVER}/AeroMegh/AeroCapture/addTask`,
  GET_ALL_PROJECTS = `${SERVER}/AeroMegh/AeroCapture/projects`,
  GET_PLANS = `${SERVER}/AeroMegh/AeroCapture/plans`,
  GET_IMAGE_COLLECTIONS = `${SERVER}/AeroMegh/AeroCapture/imageCollections`,
  ADMIN = "da7b31ee-4fc6-4d4c-a870-9673dc1d3a92",
  GET_TASKS = `${SERVER}/AeroMegh/AeroCapture/tasks`,
  ADD_IMAGE_COLLECTION_SET = `${SERVER}/AeroMegh/AeroCapture/addImageCollection`,
  ADD_IMAGE_TO_COLLECTIONS = `${SERVER}/AeroMegh/AeroCapture/image/upload`,
  CHECK_UPLOAD_STATUS = `${SERVER}/AeroMegh/AeroCapture/image/uploadStatus`,
  DELETE_IMAGES_FROM_COLLECTION = `${SERVER}/AeroMegh/AeroCapture/images/delete`,
  GET_RECENT_PLANS = `${SERVER}/AeroMegh/AeroCapture/recentPlans`,
  GET_TASK_DETECTIONS = `${SERVER}/AeroMegh/AeroCapture/detection/results`,
  START_AEROCAPTURE_TASK = `${SERVER}/AeroMegh/AeroCapture/detection/tasks/start`,
  GET_AEROCAPTURE_TASK = `${SERVER}/AeroMegh/AeroCapture/detection/tasks`,
  GET_ALL_DETECTION_OBJECTS = `${SERVER}/AeroMegh/AeroCapture/detection/allObjects`,
  DELETE_TASK = `${SERVER}/AeroMegh/AeroCapture/detection/tasks`,
  DELETE_AEROCAPTURE_PLAN = `${SERVER}/AeroMegh/AeroCapture/plans`,
  DELETE_IMAGE_COLLECTION = `${SERVER}/AeroMegh/AeroCapture/imageCollections`,
  DELETE_AEROCAPTURE_PROJECT = `${SERVER}/AeroMegh/AeroCapture/projects`,
  GET_REPORTS = `${SERVER}/AeroMegh/AeroCapture/reports`,
  GET_IMAGE_COLLECTION_IMAGES = `${SERVER}/AeroMegh/AeroCapture/imageCollection/images`,
  GET_ALL_USER_TASKS = `${SERVER}/AeroMegh/AeroCapture/project/getTotalTasks`,
  GET_ANNOTATIONSETS = `${SERVER}/AeroMegh/AeroCapture/getAllAnnotationSets`,
  GET_ANNOTATIONSET = `${SERVER}/AeroMegh/AeroCapture/getAnnotationSetDetails`,
  DELETE_ANNOTATIONSET = `${SERVER}/AeroMegh/AeroCapture/deleteAnnotationSet`,
  ADD_ANNOTATIONSET = `${SERVER}/AeroMegh/AeroCapture/addAnnotationSet`,
  GET_ANNOTATION_SET_IMAGES = `${SERVER}/AeroMegh/AeroCapture/annotationSet/images`,
  CLEAR_ANNOTATIONS = `${SERVER}/AeroMegh/AeroCapture/deleteOneAnnotation`,
  GET_MODELS = `${SERVER}/AeroMegh/AeroCapture/detection/detectors`,
  REQUEST_SERVICE = `${SERVER}/AeroMegh/requestNewService`,
  DUPLICATE_ANNOTATION_SET = `${SERVER}/AeroMegh/PicStork/copyAnnotationSet`,
  UPDATE_TASK_DETAILS = `${SERVER}/AeroMegh/PicStork/updateTaskDetails`,
  GET_ALL_DSM_IMAGES = `${SERVER}/AeroMegh/AeroCapture/imageCollection/dsm/images`,
  GET_DETECTOR_DETAILS = `${SERVER}/AeroMegh/PicStork/detector`,
  GET_LINE_TEMPERATURE = `${tilesServer}/picstork/getLineTemp`,
  GET_SINGLE_TEMPERATURE = `${tilesServer}/picstork/getSinglePixelTemp`,
  GET_RECT_TEMPERATURE = `${tilesServer}/picstork/getRectTemp`,
  GET_THERMAL_META = `${tilesServer}/picstork/getThermalMeta`,
  //Aerofarm
  GET_ORTHOMOSAIC_IMAGES = `${SERVER}/AeroMegh/AeroFarm/getAllOrthomosaicImages`,
  GET_MAP_ASSETS = `${SERVER}/AeroMegh/AeroFarm/getMapAssets`,
  DELETE_MAP_ASSETS = `${SERVER}/AeroMegh/AeroFarm/deleteMapAssets`,
  DELETE_MULTIPLE_MAP_ASSETS = `${SERVER}/AeroMegh/AeroFarm/deleteMultipleMapAssets`,

  RENAMECOMPONENT = `${SERVER}/Aeromegh/AeroFarm/renameComponent`,
  DELETE_ORTHOMOSAIC = `${SERVER}/Aeromegh/AeroFarm/deleteOrthomosaicImage`,
  GET_TASK_DETAILS = `${SERVER}/Aeromegh/AeroFarm/getTask`,
  UPDATE_TASK = `${SERVER}/Aeromegh/AeroFarm/updateTask`,
  SAVE_GCP = `${SERVER}/AeroMegh/AeroFarm/insertCollectionGcp`,
  GET_GCP = `${SERVER}/AeroMegh/AeroFarm/getCollectionGcp`,
  DELETE_GCP = `${SERVER}/AeroMegh/AeroFarm/deleteCollectionGcp`,
  USER_TASK_LOG_UPDATE = `${SERVER}/AeroMegh/AeroFarm/getUserTaskLog`,
  DOWNLOAD_CSV_TEMPLATE = `${SERVER}/AeroMegh/AeroFarm/getCSVTemplate`,
  GET_RECENT_MAPS = `${SERVER}/AeroMegh/AeroFarm/getRecentOrthoImages`,
  GET_ALL_MAPS = `${SERVER}/AeroMegh/AeroFarm/getAllOrthoImages`,
  CHECK_INVALID_IMAGES = `${SERVER}/AeroMegh/AeroFarm/processPreCheck`,
  DOWNLOAD_GCP_CSV = `${SERVER}/AeroMegh/AeroFarm/downloadGCPCSV`,
  EXPORT_IMAGES_WITH_GCP = `${SERVER}/AeroMegh/exportImagesWithGCPData`,
  GET_YIELD_ANALYSIS = `${SERVER}/AeroMegh/AeroFarm/getYieldAnalysis`,
  SAVE_YIELD_ANALYSIS = `${SERVER}/AeroMegh/AeroFarm/saveYieldAnalysis`,

  //measurements
  GET_MEASUREMENTS = `${SERVER}/AeroMegh/AeroFarm/getMeasurements`,
  GET_MEASUREMENTS_IN_THREED = tilesServer + "/get3dMeasurements",
  SAVE_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/saveMeasurement`,
  UPDATE_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/updateMeasurement`,
  DELETE_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/deleteMeasurement`,
  COMPARE_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/addToComparison`,
  GET_MEASUREMENT_COMPARISON = `${SERVER}/AeroMegh/AeroFarm/getMeasurementComparison`,

  // Global Measurements
  GET_GLOBAL_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/getPlanMeasurements`,
  SAVE_GLOBAL_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/savePlanMeasurement`,
  UPDATE_GLOBAL_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/updatePlanMeasurement`,
  DELETE_GLOBAL_MEASUREMENT = `${SERVER}/AeroMegh/AeroFarm/deletePlanMeasurement`,

  //subscription 
  AEROMEGH_PLANS = `${SERVER}/AeroMegh/SubModule/getAllSubPlans`,
  SAVE_PAYMENT_DETAILS = `${SERVER}/savePayment`,
  GET_PAYMENT_DETAILS = `${SERVER}/getpayment`,
  //teams starts here 
  GET_ROLES = `${SERVER}/AeroMegh/teams/roles`,
  GET_PROJECT_ROLE = `${SERVER}/AeroMegh/teams/project/role`,
  GET_FEATURES_PERMISSIONS = `${SERVER}/AeroMegh/teams/roles/features`,
  GET_TEAM_MEMBERS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getTeamMembers`,
  PROJECT_ACCESS = `${SERVER}/AeroMegh/teams/project/access`,
  INVITE_USER_TO_TEAM = `${SERVER}/AeroMegh/AeroGCSEnterprise/inviteUser`,
  GET_TEAMS_LIST = `${SERVER}/AeroMegh/teams`,
  DELETE_TEAM_MEMBER = `${SERVER}/AeroMegh/AeroGCSEnterprise/deleteMember`,
  GET_PERMISSIONS_CONSTANTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getPermissionConstants`,
  GET_PROJECT_DATA = `${SERVER}/AeroMegh/getProjectData`,
  DELETE_PROJECT_DATA = `${SERVER}/AeroMegh/deleteProjectData`,
  ASSIGN_TASK_TO_TM = `${SERVER}/AeroMegh/planner/assign`,
  GET_ASSIGNED_TASKS = `${SERVER}/AeroMegh/planner/tasks`,
  UPDATE_ASSIGNED_TASKS = `${SERVER}/AeroMegh/planner/tasks`,
  DELETE_ASSIGNED_TASKS = `${SERVER}/AeroMegh/planner/tasks`,


  // subscription plan API
  SAVE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/addNewSubPlan`,
  DELETE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/deleteSubPlan`,
  UPDATE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/updateSubPlan`,
  GET_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/getActiveSubPlans`,
  GET_ALL_SUBSCRIPTION_PLANS = `${SERVER}/AeroMegh/SubModule/getAllSubPlans`,
  SAVE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/addNewSubItem`,
  DELETE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/deleteSubItem`,
  UPDATE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/updateSubItem`,
  GET_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/getAllSubItems`,
  ADD_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/addSubPlanItem`,
  GET_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/getSubPlanItems`,
  UPDATE_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/updateSubPlanItem`,
  DELETE_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/deleteSubPlanItem`,
  ADD_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/addNewUserSubPlan`,
  UPDATE_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/updateUserSubPlan`,
  CANCEL_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/cancelUserSubPlan`,

  GET_LATEST_IMAGE_SETS = `${SERVER}/Aeromegh/getRecentImageCollections`,

  GET_PLAN_ON_MAP = `${SERVER}/AeroMegh/AeroGCSEnterprise/getPlanOnMap`,
  GET_PLAN_ON_MAP_DETAILS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getPlanStatistics`,
  // user subscription plans API

  GET_USER_SUBSCRIPTION_PLANS = `${SERVER}/AeroMegh/SubModule/getUserSubscriptionDetails`,

  // plant health report
  ADD_DN_REPORT = `${SERVER}/AeroMegh/AeroFarm/addDNReport`, //pass json object.
  GET_ALL_DN_REPORT = `${SERVER}/AeroMegh/AeroFarm/getDNReport`, // pass task id in url.
  DELETE_DN_REPORT = `${SERVER}/AeroMegh/AeroFarm/deleteDNReport`, // pass report id in url.

  // report header 
  GET_REPORT_HEADER = `${SERVER}/AeroMegh/AeroFarm/getDNReportHeader`, // pass task id and report type in url
  UPDATE_REPORT_HEADER = `${SERVER}/AeroMegh/AeroFarm/updateDNReportHeader`, // pass reportheader id and report type in url
  ADD_REPORT_HEADER = `${SERVER}/AeroMegh/AeroFarm/addDNReportHeader`,
  UPLOAD_COMPANY_LOGO = `${SERVER}/AeroMegh/AeroFarm/uploadDNReportHeaderLogo`,
  GET_GCP_ERROR = `${SERVER}/Aeromegh/AeroFarm/getGCPErrors`,
  //for admin
  GET_VMS = `${SERVER}/AeroMegh/getAllVms`,
  GET_ALL_TASKS_FOR_ADMIN = `${SERVER}/AeroMegh/AeroCapture/allTasks`,
  CHANGE_VM_STATUS = `${SERVER}/AeroMegh/changeVmStatus`,
  ADD_NEW_VM = `${SERVER}/AeroMegh/addVm`,
  SUPPORT_EMAIL = `${SERVER}/AeroMegh/support`,
  GET_ALL_TASKS_LOGS = `${SERVER}/AeroMegh/AeroCapture/allTaskLogs`,
  UPDATE_VM = `${SERVER}/AeroMegh/updateVm`,


  // task zip all assets
  GET_ZIP_FILE = `${SERVER}/AeroMegh/AeroFarm/droneNakshaZip`,

  // share assets 
  INVITE_USER_TO_SHARE_ASSETS = `${SERVER}/AeroMegh/shareAssets/inviteUser`,
  GET_INVITE_USERS_LIST = `${SERVER}/AeroMegh/shareAssets/getInvitedUserList`,
  GET_SHARED_DATA = `${SERVER}/AeroMegh/shareAssets/getShareData`,
  DELETE_SHARED_DATA = `${SERVER}/AeroMegh/shareAssets/deleteShareAsset`,

  //GCP API
  UPLOAD_GCP_CSV = `${SERVER}/Aeromegh/AeroFarm/uploadGcpCsv`,
  GET_CSV_GCP = `${SERVER}/Aeromegh/AeroFarm/getGCPs`,
  UPDATE_GCP_RADIUS = `${SERVER}/Aeromegh/AeroFarm/updateRadius`,

  //Global search
  GLOBAL_SEARCH = `${SERVER}/Aeromegh/globalSearch`,

  // duplicate Imageset
  DUPLICATE_IMAGE_SET = `${SERVER}/AeroMegh/copyCollection`,

  // DN Map sharing API
  IS_USER_PROJECT_OWNER = `${SERVER}/AeroMegh/shareAssets/checkProjectStatus`,
  ACCEPT_INVITE = `${SERVER}/AeroMegh/shareAssets/acceptInvite`,

  //Import Dn maps into imageCollection
  IMPORT_MAPS_INTO_COLLECTION = `${SERVER}/AeroMegh/PicStork/importOrthomosaicToImageset`,

  //Usage statistics
  GET_USAGE_STATISTICS = `${SERVER}/AeroMegh/usageStats`,

  // auth2 apis
  USER_AUTH_OTP = `${SERVER}/userOTP`,
  CHANGE_PHONE_NUMBER_REQ_OTP = `${SERVER}/changePhoneNumberReqOTP`,
  UPDATE_NUMBER_WITH_OTP = `${SERVER}/updateNumberWithOTP`,
  USER_VERIFY_OTP = `${SERVER}/verifyUserOTP`,
  GET_OTP_TIMER = `${SERVER}/timerOTP`,
  UPDATE_TWO_STEP_AUTH = `${SERVER}/AeroMegh/user/otp`,

  //Survey API
  ADD_SURVEY_DETAILS = `${SERVER}/AeroMegh/Analytic/addSurveyRecord`,
  CHECK_SURVEY_STATUS = `${SERVER}/AeroMegh/Analytic/checkUserSurveyStatus`,
  DOWNLOAD_SURVEY_RECORDS = `${SERVER}/AeroMegh/Analytic/getUserSurveyRecords`,

  //Analytics Tool 
  ADD_USER_ANALYTIC = `${SERVER}/AeroMegh/Analytic/addUserAnalytic`,
  GET_USER_ANALYTICS = `${SERVER}/AeroMegh/Analytic/getUserAnalytic`,
  GET_USER_PAGE_ANALYTICS = `${SERVER}/AeroMegh/Analytic/getUserPageAnalytic`,
  UPDATE_PAGE_ANALYTICS = `${SERVER}/AeroMegh/Analytic/updateOutTime`,
  GET_USER_STATISTICS = `${SERVER}/AeroMegh/Analytic/getUserStats`,
  GET_USER_SIGNUP_DATA = `${SERVER}/AeroMegh/Analytic/users`,
  DOWNLOAD_SIGNUP_RECORDS = `${SERVER}/AeroMegh/Analytic/usersdata`,

  //Export ML annotation 
  EXPORT_ANNOTATION_FILE = `${SERVER}/AeroMegh/PicStork/exportAnnotation`,
  EXPORT_ANNOTATION_DATA = `${SERVER}/AeroMegh/PicStork/exportAnnotationData`,
  EXPORT_DETECTION_FILE = `${SERVER}/AeroMegh/PicStork/exportDetection`,
  EXPORT_DETECTION_DATA = `${SERVER}/AeroMegh/PicStork/exportDetectionData`,

  GET_GRAPH_DATA = `${SERVER}/AeroMegh/PicStork/getGraphData`,
  GET_STAT_DATA = `${SERVER}/AeroMegh/PicStork/getStatData`,
  GET_USER_DETECTORS = `${SERVER}/AeroMegh/PicStork/detectors`,
  DELETE_DETECTOR = `${SERVER}/AeroMegh/PicStork/detectors`,
  UPLOAD_PUBLIC_DETECTOR = `${SERVER}/AeroMegh/PicStork/addDetector`,
  ADD_ANNOTATION_REPORT_TASK = `${SERVER}/AeroMegh/PicStork/addTask/AnnotationReport`,
  DOWNLOAD_DETECTOR_ZIP = `${SERVER}/AeroMegh/PicStork/downloadUserDetector`,

  // userLoginSigup
  LOGIN_USER = `${SERVER}/login`,
  RESET_PASSWORD = `${SERVER}/requestPasswordResetLink`,
  SIGNUP = `${SERVER}/signup`,

  //download PDF
  GENERATE_DEFECT_PDF = `${SERVER}/AeroMegh/PicStork/generateDefectPdf`,
  GENERATE_ORTHO_DEFECT_PDF = `${tilesServer}/picStork/defectReport`,

  GENERATE_THERMAL_PDF = `${SERVER}/AeroMegh/PicStork/generateThermalPdf`,
  GENERATE_DETECTION_PDF = `${SERVER}/AeroMegh/PicStork/generateDetectionPdf`,
  DOWNLOAD_PDF_FILE = `${SERVER}/AeroMegh/PicStork/downloadPdf`,

  GET_COUNTRY_COUNT = `${SERVER}/AeroMegh/Analytic/countryData`,
  GET_CUSTOM_STATS = `${SERVER}/AeroMegh/Analytic/getCustomStats`,
  GET_INDUSTRY_DATA = `${SERVER}/AeroMegh/Analytic/getIndustryData`,

  DOWNLOAD_COLLECTION_ZIP = `${SERVER}/AeroMegh/imageset/download`,

  COPY_TASK_COLLECTION = `${SERVER}/AeroMegh/imageset/copy`,
  RENAME_PROJECT = `${SERVER}/AeroMegh/project/rename`,

  // project stat APIs
  GET_PROJECT_STATE = `${SERVER}/AeroMegh/getProjectStats`,

  //windmill apis
  GET_WINDMILL_IMAGE = `${SERVER}/AeroMegh/PicStork/getWindMillImage`,
  SAVE_WINDMILL_ANNOTATION = `${SERVER}/AeroMegh/PicStork/getWindMillImage`,

  //Notification 
  //Notifications 
  GET_NOTIFICATIONS = `${SERVER}/AeroMegh/notifications`,
  ADD_NOTIFICATION = `${SERVER}/AeroMegh/notifications`,

  // credit Routes
  GET_CREDIT_DETAILS = `${SERVER}/AeroMegh/SubModule/getTaskCreditsDetails`,
  GET_USER_CREDITS_PAYMENT_DETAILS = `${SERVER}/AeroMegh/tasksCreditsPlans/payments`,
  //Floor
  ADD_FLOOR = `${SERVER}/AeroMegh/AeroFarm/floors`,
  GET_ALL_FLOORS = `${SERVER}/AeroMegh/AeroFarm/floors`,
  DELETE_FLOOR = `${SERVER}/AeroMegh/AeroFarm/floors`,
  CHECK_DUPLICATE_FLOOR_IMAGES = `${SERVER}/AeroMegh/AeroCapture/image/checkFloorImage`,
  CHECK_DUPLICATE_FLOOR_360_IMAGES = `${SERVER}/AeroMegh/AeroCapture/image/checkFloor360Image`,
  FLOOR_ISSUES = `${SERVER}/AeroMegh/AeroFarm/floors/issues`,

  // Subscription routes
  GET_STORAGE_BY_SERVICES = `${SERVER}/Aeromegh/subscription/getStorageUsage`,

  // Task credit plans
  GET_TASK_CREDIT_PLANS = `${SERVER}/AeroMegh/tasksCreditsPlans`,
  GET_ANALYTICS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getAnalyticsForAdmin`,

  // detection region apis
  ADD_DETECTIONS_REGION = `${SERVER}/AeroMegh/picstork/detections/region`,
  DELETE_DETECTIONS_REGION = `${SERVER}/AeroMegh/picstork/detections/region`,
  CLEAR_ALL_REGIONS_FROM_DETECTIONS = `${SERVER}/AeroMegh/picstork/detections/region/all`,
  UPDATE_DETECTIONS_REGION = `${SERVER}/AeroMegh/picstork/detections/region`,
  UPDATE_DETECTIONS_REGIONS_OF_CLASS = `${SERVER}/AeroMegh/picstork/detections/regions`,
  ADD_DETECTIONS_CLASSNAME = `${SERVER}/AeroMegh/picstork/detections/class`,
  CHANGE_DETECTIONS_CLASSNAME = `${SERVER}/AeroMegh/picstork/detections/class`




function getCookie(cname) {
  return cookie.get(cname);
}

var response, token;
var SESSION_ID_CHANGED = false;
export const sessionCheck = async () => {
  var cookie = getCookie("session_IAMAero");
  if (!cookie || cookie === "undefined") return Promise.reject("Error");
  const options = {
    method: "POST",
    body: JSON.stringify({
      session_id: cookie,
    }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: "Bearer a0caa289-2863-4f70-9907-367b6dc084d6",
    },
  };

  const res = await fetch(SESSION_CHECK, options);
  return await res.json();
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const groupsAndOrganizations = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GROUPS_AND_ORGANIZATIONS, options);
  return await res.json();
};
const organizationDetails = async (token, organization_id) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${ORGANIZATION_DETAILS}/${organization_id}`,
    options
  );
  return await res.json();
};


const updatePassword = async (oldPassword, newPassword, token) => {
  let bs64 = Buffer.from(`${oldPassword}:${newPassword}`).toString("base64");
  const options = {
    method: "PUT",
    body: JSON.stringify({ passwords: bs64 }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  return fetch(UPDATE_PASSWORD, options);
};

export const getMyProfile = async (username) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const user = await userSearch(token, username);

  return await user;
};

export const editPassword = async (oldPassword, newPassword) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const password = await updatePassword(
    oldPassword,
    newPassword,
    response.access_token
  );

  let ret = await password.json();
  if (ret.status_code == 1) {
    return Promise.reject("Incorrect current Password");
  } else {
    return ret;
  }
};



/* getUserType */

const userSearch = async (token, username) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${USER_SEARCH}/${username}`, options);
  return await res.json();
};

const getUserRole = (role) => {
  switch (role) {
    case MANUFACTURER:
      return "manufacturer";
    case CUSTOMER:
      return "customer";
    case ADMIN:
      return "admin";
  }
};

const getRegistrationStatus = async () => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Basic SUFNQWVybzpwZHJsMjAyMA==`,
    },
  };

  const res = await fetch(GET_REGISTRATION_STATUS, options);
  return await res.json();
};


const userType = async () => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  token = response.access_token;


  var res = await userSearch(token, response.username);


  var role = res.users[0].role_id;

  if (role === MANUFACTURER) {
    const status = await getRegistrationStatus();
    const { registered } = status.mfg_registration_status[0];

    return Promise.resolve([registered, getUserRole(role), res.users[0]]);
  }

  return Promise.resolve([2, getUserRole(role), res.users[0]]);
};

export const getUserType = async () => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
    return sleep(500).then(() => userType());
  } else {

    return sleep(500).then(() => userType());
  }
};



export const logMeOut = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer e20fa126-7a9c-4fb1-bcb4-ce25e14e6e94`,
    },
  };

  const raw_res = await fetch(
    `${SERVER}/logout/?user=${response.username}`,
    options
  );
  const res = await raw_res.json();



  return new Promise((resolve, reject) => {
    return resolve(res);
  });
};


export const getUserSubbedServices = async (targetUser) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${response.access_token}`,
    },
  };

  let raw_res = null;
  if (targetUser) {
    raw_res = await fetch(
      `${SERVER}/AeroMegh/subbedServices?user=${targetUser}`,
      options
    );
  } else {
    raw_res = await fetch(`${SERVER}/AeroMegh/subbedServices`, options);
  }

  const apiResponse = await raw_res.json();
  const res = apiResponse.services;

  const images = require.context("./", true);
  res.forEach((service) => {
    service.redirectURL = service.redirecturl;
    service.image = images("./" + service.image);
  });
  const resp = apiResponse.allServices;
  return new Promise((resolve, reject) => {
    return resolve([res, resp]);
  });
};

const UserEdit = async (token, user) => {
  const options = {
    method: "PUT",
    body: JSON.stringify({
      user: user,
    }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(USER_EDIT, options);

  return await res.json();
};

// get User details API
export const getUser = async (userid, isAdmin) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${USER_EDIT}/${userid}?isAdmin=${isAdmin}`, options);
  return await res.json();
};

export const Useredit = async (user) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await UserEdit(token, user);



  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    } else {
      return reject(res);
    }
  });
};

const UserDelete = async (token, user) => {
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(USER_EDIT + "/" + user.userid, options);
  return await res.json();
};

export const deleteUser = async (user) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") {
    return Promise.reject("Error");
  }

  const res = await UserDelete(token, user);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    }
    return reject(res);
  });
};

const get_users = async (token, searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let sorting = sortBy && sort ? `&sortBy=${sortBy}&sort=${sort}` : ''
  let queryStr = `?status=${status}&fromDate=${fromDate}&toDate=${toDate}${sorting}`
  const res = await fetch(
    `${GET_USERS}/${encodeURIComponent(searchString)}/${startIndex}/${pageSize}${queryStr}`,
    options
  );
  return await res.json();
};

export const users = async (searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_users(token, searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate);


  return new Promise((resolve, reject) => {
    if (res.users) {
      return resolve(res);
    }
    return reject(Error("cannot fetch users"));
  });
};

export const postServicesAssignment = async (services, userid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${response.access_token}`,
    },
    body: JSON.stringify(services),
  };

  const raw_res = await fetch(
    `${SERVER}/AeroMegh/subbedServices/${userid}`,
    options
  );
  const res = await raw_res.json();

  return new Promise((resolve, reject) => {
    if (res.message == "services updated successfully") {
      return resolve(res.message);
    }
    return reject(res);
  });
};


const check_plan = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${GET_PLAN}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

export const plan = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await check_plan(token);


  return new Promise((resolve, reject) => {
    if (res.plan) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};


const check_Plan = async (token, projectName, planName, projectId) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${IS_PLAN_EXIST}/${encodeURIComponent(projectId)}/${encodeURIComponent(projectName)}/${encodeURIComponent(
      planName
    )}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};




export const checkPlan = async (projectName, planName, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await check_Plan(token, projectName, planName, projectId);


  return new Promise((resolve, reject) => {

    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};




export const createPlan = async (plan) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      ...plan,
      teamId: await getActiveTeamId()
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_PLAN}?teamId=${await getActiveTeamId()}`, options);

  return await res.json();
};




const get_users_stats = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(USER_STATS, options);
  return await res.json();
};
export const getUsersStats = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const res = await get_users_stats(token);

  return new Promise((resolve, reject) => {
    if (res) {
      return resolve(res);
    }
    return reject(Error("cannot delete project"));
  });
};

//get annotation
export const getAnnotation = async (fileName, collection_id, annotation_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  let path = `${GETANNOTATION}/${fileName}/${collection_id}/${annotation_id}`;
  const options = {
    method: "GET",
    //  body: JSON.stringify(regions),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GETANNOTATION}/${fileName}/${collection_id}/${annotation_id}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

//Save annotation
export const saveAnnotation = async (annotatedData, onUploadProgress, classesObj, task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;

  return axios.post(`${SAVEANNOTATION}?teamId=${await getActiveTeamId()}${task_id ? `&task_id=${task_id}` : ""}`, { annotatedData, classesObj }, {
    headers: {
      // ...data.getHeaders(),
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    onUploadProgress,
  });
};



// /AeroMegh/ml_tool/tasks/startTaskTraining/:taskName
const startTaskCallAPI = async (token, taskName) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${TASK_START}/${taskName}`, options);
  return await res.json();
};

export const startTask = async (taskName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") {
    return Promise.reject("Error");
  }

  const res = await startTaskCallAPI(token, taskName);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    }
    return reject(res);
  });
};


const get_invoice = async (token, invoiceID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_INVOICE}/${invoiceID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getInvoice = async (invoiceID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_invoice(token, invoiceID);
  return new Promise((resolve, reject) => {
    if (res.message == "FOUND") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoice. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const get_my_invoices = async (token, pageSize, pageNumber) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_INVOICE}/${pageSize}/${pageNumber}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getMyInvoices = async (pageSize, pageNumber) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_my_invoices(token, pageSize, pageNumber);
  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoices. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const admin_get_users_invoices = async (
  token,
  userID,
  pageSize,
  pageNumber
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${ADMIN_USER_INVOICES}/${userID}/${pageSize}/${pageNumber}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const adminGetUsersInvoices = async (userID, pageSize, pageNumber) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await admin_get_users_invoices(
    token,
    userID,
    pageSize,
    pageNumber
  );
  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoices. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

//ADMIN_SEND_INVOICE_REMINDER
const admin_send_invoice_reminder = async (token, invoiceID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${ADMIN_SEND_INVOICE_REMINDER}/${invoiceID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const adminSendInvoiceReminder = async (invoiceID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await admin_send_invoice_reminder(token, invoiceID);
  return new Promise((resolve, reject) => {
    if (res.message == "SENT") {
      return resolve(res);
    } else {
      return reject(
        "Failed to send invoice reminder. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const get_countries = async () => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_COUNTRIES}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get countries
export const getCountries = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_countries();
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get countries");
  });
};

const get_states = async (countryCode) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_STATES}/${countryCode}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get states
export const getStates = async (countryCode) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_states(countryCode);
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get states");
  });
};








//AEROMEGH_PLANS
const get_aeromegh_plans = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${AEROMEGH_PLANS}`, options);
  return await res.json();
};

export const getAeromeghPlans = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_aeromegh_plans(token);
  return new Promise((resolve, reject) => {
    if (res.plans) {
      return resolve(res);
    } else {
      return reject(
        "Failed to get plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};








//CANCEL_SUBSCRIPTION

export const cancelSubscription = async (userSubId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const options = {
    method: "DELETE",
    // body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CANCEL_SUBSCRIPTION}/${userSubId}`, options);
  return await res.json();
};

//UPDATE_SUBSCRIPTION
const update_subscription = async (token, subID, data) => {
  const options = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${UPDATE_SUBSCRIPTION}/${subID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const updateSubscription = async (subID, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await update_subscription(token, subID, data);

  return new Promise((resolve, reject) => {
    if (res.message == "UPDATED") {
      return resolve(res);
    } else {
      return reject(
        "Failed to subscribe plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};


//create aerocapture project
export const createNewProject = async (project) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(project),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_NEW_PROJECT}?teamId=${await getActiveTeamId()}`, options);
  if (res.status === 400) return ({
    error: "Project already exists with same name."
  })
  else if (res.status === 409) return ({
    error: "Project already exists with same name."
  })
  else return await res.json();
};

//create aerocapture task

export const createTask = async (task) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(task),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_TASK}?teamId=${await getActiveTeamId()}`, options);
  // return await res.json();
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    } else {
      res.json().then(data => reject({ ...data, creditExceeds: res.status == 202 }))
    }
  });
};

const get_all_projects = async (token, searchString, pageSize, startIndex, filterRole, serviceName, fromDate, toDate, orderBy, orderType) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let d = `${GET_ALL_PROJECTS}/${searchString}/${startIndex}/${pageSize}?teamId=${await getActiveTeamId()}${filterRole ? `&filterRole=${filterRole}` : ""}&service=${serviceName}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get all aerocapture projects
export const getAllProjects = async (searchString, pageSize, startIndex, filterRole, serviceName, fromDate, toDate, orderBy, orderType) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_all_projects(token, searchString, pageSize, startIndex, filterRole, serviceName, fromDate, toDate, orderBy, orderType);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot fetch projects");
  });
};

const get_plans = async (
  token,
  projectName,
  searchString,
  pageSize,
  pageNumber,
  tasktype,
  projectId,
  fromDate,
  toDate,
  orderBy,
  orderType
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  // let team = teamId ? teamId : undefined
  let type = tasktype ? tasktype : 0
  let d = `${GET_PLANS}/${encodeURIComponent(projectId)}/${encodeURIComponent(projectName)}/${encodeURIComponent(searchString)}/${pageNumber}/${pageSize}?teamId=${await getActiveTeamId()}&tasktype=${type}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}`;
  const res = await fetch(d, options);
  return res;
};

//get all aerocapture plans by project name
export const getPlans = async (
  projectName,
  searchString,
  pageSize,
  pageNumber,
  tasktype,
  projectId,
  fromDate,
  toDate,
  orderBy,
  orderType
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_plans(
    token,
    projectName,
    searchString,
    pageSize,
    pageNumber,
    tasktype,
    projectId,
    fromDate,
    toDate,
    orderBy,
    orderType
  );

  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json())
    else resolve([])
  });
};
//Get all task of AeroCapture
export const getAllTask = async (
  project_id,
  plan_id,
  taskName,
  startIndex,
  pageSize,
  service
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  //const res = await fetch(`${GET_TASKS}?taskName=${taskName}&startIndex=${startIndex}&pageSize=${pageSize}`, options);
  const res = await fetch(
    `${GET_TASKS}/${service}/${project_id}/${plan_id}/${encodeURIComponent(taskName)}/${startIndex}/${pageSize}?teamId=${await getActiveTeamId()}`,
    options
  );

  return await res.json();
};

//Get all task of AeroCapture
export const getImageCollections = async (project_id, plan_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  if (type) {
    const res = await fetch(
      `${GET_IMAGE_COLLECTIONS}/${project_id}/${plan_id}?type=${type}&teamId=${await getActiveTeamId()}`,
      options
    );
    return await res.json();
  } else {
    const res = await fetch(
      `${GET_IMAGE_COLLECTIONS}/${project_id}/${plan_id}?teamId=${await getActiveTeamId()}`,
      options
    );
    return await res.json();
  }



};

//Aerocapture check upload files status before uploading images 
export const checkUploadStatus = async (
  collection_id,
  file_names
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ collection_id: collection_id, file_names: file_names }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHECK_UPLOAD_STATUS}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

// FloorPlanning images check 
export const checkDuplicateFloorImage = async (
  floor_id,
  file_names,
  imageType,
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ floorId: floor_id, file_names: file_names, image_type: imageType }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHECK_DUPLICATE_FLOOR_IMAGES}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};


// Aerocapture upload image to collection
export const uploadImageToCollection = async (
  collection_id,
  data,
  onUploadProgress,
  imageType
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  // const res = await fetch(`${ADD_IMAGE_TO_COLLECTIONS}/${collection_id}`, options);
  return axios.post(`${ADD_IMAGE_TO_COLLECTIONS}/${collection_id}?teamId=${await getActiveTeamId()}${imageType ? `&imageType=${imageType}` : ""}`, data, {
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
    onUploadProgress,
  });
  // 
  // return await res.json();
};

export const createImageCollection = async (
  project_id,
  plan_id,
  collection_name
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      project_id: project_id,
      plan_id: plan_id,
      collection_name: collection_name,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_IMAGE_COLLECTION_SET}?teamId=${await getActiveTeamId()}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else if (res.status == 409) {
      return resolve(res);
    } else {
      return reject(res.message);
    }
  });
};


export const renameCollection = async (renameData) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(renameData),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${RENAMECOMPONENT}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    // if (res.status == 200) {
    //   return resolve(res.json());
    // } else if (res.status == 409) {
    //   return resolve(res);
    // } else {
    //   return reject(res.message);
    // }
    return resolve(res)
  });
};

//Get all task of AeroCapture
export const getImageCollectionImages = async (
  collection_id,
  searchString,
  pageSize,
  pageNumber
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_IMAGE_COLLECTION_IMAGES}/${collection_id}/${encodeURIComponent(searchString)}/${pageSize}/${pageNumber}?teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return await res.json();
};

export const getRecentPlans = async (pageSize, pageNumber, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_RECENT_PLANS}/${pageNumber}/${pageSize}?service=${service}`,
    options
  );

  return await res.json();
};

//Get all task of AeroCapture
export const deleteImages = async (collection_id, images, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({
      images: images,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_IMAGES_FROM_COLLECTION}/${collection_id}?service=${service}&teamId=${await getActiveTeamId()}`,
    options
  );

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res);
    }
    return reject("Cannot delete images");
  });
};

//save detections task result
export const saveDetectionResult = async (task_id, image, result, linked_map) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      image: image,
      result: result,
      task: task_id,
      linked_map,
      isFromWeb: true,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_TASK_DETECTIONS}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject("cannot save detection result");
  });
};

export const getLineTemperature = async (XYval, blob_container, sas_, collection_id, image_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_LINE_TEMPERATURE}/${XYval}?blobContainer=${blob_container}&sas=${encodeURIComponent(sas_)}&collectionId=${collection_id}&imageName=${image_name}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

//get single pixle temperature

export const getSinglePixleTemperature = async (XYval, blob_container, sas_, collection_id, image_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SINGLE_TEMPERATURE}/${XYval}?blobContainer=${blob_container}&sas=${encodeURIComponent(sas_)}&collectionId=${collection_id}&imageName=${image_name}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};
// get rectangle temperature

export const getRectTemperature = async (x_y_val, blob_container, sas_, collection_id, image_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_RECT_TEMPERATURE}/${x_y_val}?blobContainer=${blob_container}&sas=${encodeURIComponent(sas_)}&collectionId=${collection_id}&imageName=${image_name}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

// get thermal meta

export const getThermalMeta = async (blob_container, sas_, collection_id, image_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_THERMAL_META}?blobContainer=${blob_container}&sas=${encodeURIComponent(sas_)}&collectionId=${collection_id}&imageName=${image_name}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

//get all detections of task
export const getDetections = async (task_id, objects) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      objects,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_TASK_DETECTIONS}/${task_id}?teamId=${await getActiveTeamId()}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject("cannot get detections");
  });
};

// start aerocapture task
export const startAerocaptureTask = async (task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${START_AEROCAPTURE_TASK}/${task_id}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject("cannot start task");
  });
};


// get task
export const getAeroCaptureTask = async (task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_AEROCAPTURE_TASK}/${task_id}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else if (res.status == 202) {
      res.json().then(data => reject({ message: 'Task not found.' }))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

//Add new object in the task
export const updateAeroCaptureTask = async (task_id, object) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "object": object
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_AEROCAPTURE_TASK}/${task_id}?teamId=${await getActiveTeamId()}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject(res);
  });
};

// get all detection objects
export const getAllDetectionObjects = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ALL_DETECTION_OBJECTS}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject("cannot get objects");
  });
};

// delete aerocapture task
export const deleteAerocaptureTask = async (task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_TASK}/${task_id}?teamId=${await getActiveTeamId()}`, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    }
    return reject("cannot delete task");
  });
};

// deleteAerocapturePlan
export const deleteAerocapturePlan = async (plan_id, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_AEROCAPTURE_PLAN}/${plan_id}?service=${service}&teamId=${await getActiveTeamId()}`, options);

  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

// delete aerocapture task
export const deleteAerocaptureImageCollection = async (collection_id, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_IMAGE_COLLECTION}/${collection_id}?service=${service}&teamId=${await getActiveTeamId()}`,
    options
  );


  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

// delete aerocapture project
export const deleteAerocaptureProject = async (project_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_AEROCAPTURE_PROJECT}/${project_id}`,
    options
  );


  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

//Get all reports of AeroCapture
export const getAllReports = async (
  project_id,
  plan_id,
  taskName,
  reportType,
  startIndex,
  pageSize
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  //const res = await fetch(`${GET_TASKS}?taskName=${taskName}&startIndex=${startIndex}&pageSize=${pageSize}`, options);
  const res = await fetch(
    `${GET_REPORTS}/${project_id}/${plan_id}/${encodeURIComponent(
      taskName
    )}/${reportType}/${startIndex}/${pageSize}`,
    options
  );

  return await res.json();
};


//Get all tasks of user

export const GetAllTasksOfUser = async (pageSize, startIndex, taskName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ALL_USER_TASKS}/${taskName}/${startIndex}/${pageSize}`, options);
  return await res.json();
};

//teams starts here
export const getProjectRoleAPI = async (teamId, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_PROJECT_ROLE}/${teamId}/${projectId}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const getFeaturePermissionsAPI = async (teamId, roleId, features) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ teamId, roleId, features }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_FEATURES_PERMISSIONS}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
}

export const getRolesAPI = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_ROLES}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};



export const getTeamUsers = async (team_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_TEAM_MEMBERS}/${team_id}`, options);
  return await res.json();
};


export const getProjectAccessListAPI = async (teamId, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${PROJECT_ACCESS}/${teamId}/${projectId}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const addProjectAccessAPI = async (projectId, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({ projectId, teamId, roleId, username, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(PROJECT_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const removeProjectAccessAPI = async (projectId, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({ projectId, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${PROJECT_ACCESS}/${teamId}/${projectId}/${username}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const updateProjectAccessAPI = async (projectId, teamId, username, roleId, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({ projectId, teamId, username, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(PROJECT_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const inviteUserToTeam = async (userMail, teamId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "user_name": userMail,
      "team_id": teamId
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(INVITE_USER_TO_TEAM, options);
  return await res;
};

export const getTeamsList = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GET_TEAMS_LIST, options);
  return await res.json();
};
export const deleteUserFromTeam = async (teamId, username) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({
      "team_id": teamId,
      "username": username
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(DELETE_TEAM_MEMBER, options);
  return await res;
}

export const getPermissionsConstants = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GET_PERMISSIONS_CONSTANTS, options);
  return await res.json();
};

export const request_service = async (serviceTitle) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${REQUEST_SERVICE}/${serviceTitle}`, options);

  return res.json();
};

//AeroGCSEnterprise Ends here

// Get project releated data for performing delete operation
export const getProjectData = async (type, projectName, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_PROJECT_DATA}/${type}/${projectName}/${projectId}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
}


// Delete project and project releated data 
export const deleteProjectAndData = async (type, project_id, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    body: JSON.stringify({
      "type": type,
      "data": data,
      "project_id": project_id
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_PROJECT_DATA}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
}



//subscription plan APIs
export const getSubscriptionPlan = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    // body: JSON.stringify({
    //   "type": type,
    //   "data": data
    //   }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_PLAN}`, options);
  return await res.json();
}

//subscription plan APIs
export const getAllSubscriptionPlans = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    // body: JSON.stringify({
    //   "type": type,
    //   "data": data
    //   }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ALL_SUBSCRIPTION_PLANS}`, options);
  return await res.json();
}
export const saveSubscriptionPlan = async (plan_name, status, service_id, plan_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "plan_name": plan_name,
      "status": status,
      "service_id": service_id,
      "plan_type": plan_type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(SAVE_SUBSCRIPTION_PLAN, options);
  return await res.json();
};

export const updateSubscriptionPlan = async (plan_id, status, name, cost, discount, description, price_id, plan_type, discount_coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "status": status,
      "cost": cost,
      "discount": discount,
      "description": description,
      "name": name ? name.trim() : name,
      "price_id": price_id ? price_id.trim() : price_id,
      "plan_type": plan_type ? plan_type.trim() : plan_type,
      "discount_coupon_id": discount_coupon_id
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_SUBSCRIPTION_PLAN}/${plan_id}`, options);
  return await res.json();
};

export const deleteSubscriptionPlan = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_PLAN}/${id}`, options);
  return await res.json();
}


//subscription  item APIs
export const getSubscriptionItem = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    // body: JSON.stringify({
    //   "type": type,
    //   "data": data
    //   }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_ITEM}`, options);
  return await res.json();
}

export const saveSubscriptionItem = async (item_name, units) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "item_name": item_name,
      "unit": units
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(SAVE_SUBSCRIPTION_ITEM, options);
  return await res;
};

export const updateSubscriptionItem = async (item_name, unit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "item_name": item_name,
      "unit": unit
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(UPDATE_SUBSCRIPTION_ITEM, options);
  return await res;
};

export const deleteSubscriptionItem = async (item_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_ITEM}/${item_name}`, options);
  return await res.json();
}

// subscription plan item APIs
export const getSubscriptionPlanItems = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_PLAN_ITEM}/${plan_id}`, options);
  return await res.json();
}

export const saveSubscriptionPlanItem = async (plan_id, item_id, limits) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_id": plan_id,
      "item_id": item_id,
      "limit": limits
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(ADD_SUBSCRIPTION_PLAN_ITEM, options);
  return res;
};

export const updateSubscriptionPlanItem = async (plan_id, item_id, limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_id": plan_id,
      "item_id": item_id,
      "limit": limit
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(UPDATE_SUBSCRIPTION_PLAN_ITEM, options);
  return await res.json();
};

export const deleteSubscriptionPlanItem = async (sub_id, item_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_PLAN_ITEM}/${sub_id}/${item_id}`, options);
  return await res.json();
}
// ------------------------------------------

// user subscription plan APIs

export const saveUserSubscriptionPlan = async (plan_id, expiry_date, user_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_plan_id": plan_id,
      "expiry_date": expiry_date,
      "user_id": user_id,
      "sub_type": type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(ADD_USER_SUBSCRIPTION_PLAN, options);
  return res;
};

export const updateUserSubscriptionPlan = async (id, plan_id, expiry_date, user_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "plan_id": plan_id,
      "expiry_date": expiry_date,
      "user_id": user_id,
      "sub_type": type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_USER_SUBSCRIPTION_PLAN}/${id}`, options);
  return await res.json();
};

export const cancelUserSubscriptionPlan = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CANCEL_USER_SUBSCRIPTION_PLAN}/${id}`, options);
  return res;
};




// user subscription plans APIs
export const getUserSubscriptionPlan = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_SUBSCRIPTION_PLANS}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json())
    else reject(await res.json())
  })
}

//save payment details
export const savePaymentDetails = async (sessionId, planId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SAVE_PAYMENT_DETAILS}?sessionId=${sessionId}&planId=${planId}`, options);
  return await res.json();
};
export const getPaymentDetails = async (sessionId, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_PAYMENT_DETAILS}?sessionId=${sessionId}&type=${type}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200)
      resolve(await res.json());
    else
      reject(await res.json());
  })

}



//Get all orthomosaic images data of Aerofarm
export const getImageOrthomosaicImages = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ORTHOMOSAIC_IMAGES}/${plan_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return await res.json();
};

//Get assets of map of Aerofarm
export const getMapAssets = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_MAP_ASSETS}/${plan_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return await res.json();
};
export const deleteMapAssets = async (id, image_url) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_MAP_ASSETS}?id=${id}&image_url=${image_url}&teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject()
  })
};

export const deleteMultipleMapAssets = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_MULTIPLE_MAP_ASSETS}?teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json())
    else reject()
  })
};


export const deleteOrthomosaicImage = async (orthomosaic) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({ ortho: orthomosaic }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_ORTHOMOSAIC}?teamId=${await getActiveTeamId()}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

export const getTaskDetails = async (collectionId, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_TASK_DETAILS}/${collectionId}?type=${type}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const updateTask = async (taskId, status, service, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${UPDATE_TASK}/${taskId}/${status}/${encodeURIComponent(service)}?teamId=${await getActiveTeamId()}`,
    options
  );
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject({ ...data, creditExceeds: res.status == 202 }))
    }
  });
};

export const saveGCP = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SAVE_GCP}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const getGCP = async (collectionId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_GCP}/${collectionId}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const deleteGCP = async (collection_id, image_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_GCP}/${collection_id}/${image_name}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const userTaskLogUpdate = async (task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${USER_TASK_LOG_UPDATE}/${task_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

// plant health report API 


export const addDNReport = async (task_id, algorithm_type, filter_type, min, max, report_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "task_id": task_id,
      "algorithm_type": algorithm_type,
      "filter_type": filter_type,
      "min": min,
      "max": max,
      "report_type": report_type,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_DN_REPORT}?teamId=${await getActiveTeamId()}`, options);
  return res;
};

export const getAllDNReport = async (task_id, report_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_DN_REPORT}/${task_id}/${report_type}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const deleteDNReport = async (report_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_DN_REPORT}/${report_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res;
};

export const updateReportHeader = async (reportTitle, reportSubTitle, company_name, reportHeader_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "title": reportTitle,
      "subtitle": reportSubTitle,
      "name": company_name,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${UPDATE_REPORT_HEADER}/${reportHeader_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res;
};

// plant health report API end


// customise report header API start

export const getReportHeader = async (task_id, report_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_REPORT_HEADER}/${task_id}/${report_type}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


export const addReportHeader = async (task_id, report_type, title, company_logo, subtitle) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "task_id": task_id,
      "report_type": report_type,
      "title": title,
      "company_logo": company_logo,
      "subtitle": subtitle,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_REPORT_HEADER}?teamId=${await getActiveTeamId()}`, options);
  return res;
};

const uploadCompany_logo = async (token, report_id, data) => {
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${UPLOAD_COMPANY_LOGO}/${report_id}?teamId=${await getActiveTeamId()}`;
  const res = await fetch(d, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res);
    } else {
      return reject(
        "Failed to upload. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

export const uploadCompanyLogo = async (report_id, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await uploadCompany_logo(token, report_id, data);
  return res
};

// customise report header API End



//get histogram values for show histogram

export const getHistogram = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${tilesServer}/getMetadata/${data.taskId}/orthomosaic?sas=${data.sas}&blobContainer=${data.blobContainer}&algorithm=${data.algorithm}&filterType=${data.processTileType}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};
export const getTilesBounds = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${tilesServer}/getTilesBounds/${data.taskId}/${data.tiffType}?sas=${data.sas}&blobContainer=${data.blobContainer}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};



export const downloadNDVIImage = (data) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      responseType: 'blob',
      body: JSON.stringify(plan),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json"
      },
    };
    let url = `${tilesServer}/getPng/${data.taskId}/plantHealth?algorithm=${data.algorithm}&filterType=${data.processTileType}&min=${data.processTileMin}&max=${data.processTileMax}&sas=${data.sas}&blobContainer=${data.blobContainer}&teamId=${await getActiveTeamId()}`
    try {
      fetch(url, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `${data.algorithm}_${data.name}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("image not found"))

    }
    catch (error) {
      reject(error)
    }
  })
};


export const getPlansOnMap = async (project_name, project_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_PLAN_ON_MAP}/${project_id}/${project_name}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


export const getPlanOnMapDetails = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_PLAN_ON_MAP_DETAILS}/${plan_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

// Get all VMs
export const getAllVms = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_VMS}`, options);
  return await res.json();
}

//Get all tasks for admin
export const getAllTasksForAdmin = async (
  taskName,
  status,
  toDate,
  fromDate,
  startIndex,
  pageSize
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_ALL_TASKS_FOR_ADMIN}/${encodeURIComponent(
      taskName
    )}/${encodeURIComponent(status)}/${toDate}/${fromDate}/${startIndex}/${pageSize}`,
    options
  );
  return await res.json();
};

export const changeVMStatus = async (vmId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "vmId": vmId
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHANGE_VM_STATUS}`, options);
  return await res.json();
};

export const addNewVM = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_NEW_VM}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};


export const updateVM = async (data, vmid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_VM}/${vmid}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

export const getLatestImageSets = async (type, limit, id, pageNo, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    // body: JSON.stringify({
    //   "type": type,
    //   "limit": limit,
    //   "id": id
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_LATEST_IMAGE_SETS}/${type}/${id}/${limit}?service=${service}&pageNo=${pageNo}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

// support email APIs

//get all support mais lList
export const getAllSupportEmails = async (
  searchString,
  startIndex,
  pageSize
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${SUPPORT_EMAIL}/${encodeURIComponent(searchString)}/${startIndex}/${pageSize}`, options);
  return await res.json();
};

//change support email status
export const changeEmailStatus = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SUPPORT_EMAIL}/changeEnableStatus/${id}`, options);
  return await res.json();
};

//Add new support email
export const addNewSupportMail = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SUPPORT_EMAIL}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 201) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

//delete support email
export const deleteSupportEmail = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SUPPORT_EMAIL}/${id}`, options);
  return await res.json();
}

// Get all tasks logs for admin
export const getAllTaskLogs = async (
  searchString,
  startIndex,
  pageSize
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_ALL_TASKS_LOGS}/${encodeURIComponent(searchString)}/${startIndex}/${pageSize}`,
    options
  );
  return await res.json();
};

// upoload csv 
const getGcpFrom_Csv = async (token, collection_id, data, epsg) => {
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${UPLOAD_GCP_CSV}/${collection_id}/10?epsg=${epsg}&teamId=${await getActiveTeamId()}`;
  const res = await fetch(d, options);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      res.json().then(data => reject(data))
    }
  });
};

export const uploadGcpCsv = async (collection_id, data, epsg) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await getGcpFrom_Csv(token, collection_id, data, epsg);
  return res
};

//Get CSV GCP
export const getCSV_GCP = async (collection_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_CSV_GCP}/${collection_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

//Get CSV GCP
export const updateRadius = async (id, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_GCP_RADIUS}/${id}?teamId=${await getActiveTeamId()}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};


// share Assets start 

export const inviteUserToShareAssets = async (mail_id, collection_id, access_level, project_id, plan_id, collection_name, task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "mail_id": mail_id,
      "collection_id": collection_id,
      "access_level": access_level,
      "project_id": project_id,
      "plan_id": plan_id,
      "collection_name": collection_name,
      task_id
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${INVITE_USER_TO_SHARE_ASSETS}?teamId=${await getActiveTeamId()}`, options);
  return res;
};


export const getInviteUsersList = async (collection_id, task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    // body: JSON.stringify({
    //   "mail_id": mail_id,
    //   "collection_id": collection_id,
    //   "access_level": access_level,
    //   "project_id": project_id
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_INVITE_USERS_LIST}/${collection_id}${task_id ? `?task_id=${task_id}&teamId=${await getActiveTeamId()}` : `?teamId=${await getActiveTeamId()}`}`, options);
  return res.json();
};


export const getSharedData = async (project_id, shared_to, sid, task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    // body: JSON.stringify({
    //   "shared_to": shared_to
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SHARED_DATA}/${project_id}?shared_to=${shared_to}&sid=${sid}&teamId=${await getActiveTeamId()}`, options);
  return res.json();
};


export const deleteSharedData = async (sid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   "shared_to": shared_to
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SHARED_DATA}/${sid}?teamId=${await getActiveTeamId()}`, options);
  return res;
};



//share assets End// duplicate imageSet start 

export const duplicateImageSet = async (userContainer, old_id, new_id, service, filter_out_extensions) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "srcCont": userContainer,
      "destCont": userContainer,
      "srcCol": old_id,
      "destCol": new_id,
      "service": service,
      "filter_out_extensions": JSON.stringify(filter_out_extensions) || ""
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DUPLICATE_IMAGE_SET}?teamId=${await getActiveTeamId()}`, options);
  return res;
};


// duplicate imageSet End

//measurements
export const saveMeasurement = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  pushMeasurementToSocketRoom(data, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_ADDED)
  const res = await fetch(`${SAVE_MEASUREMENT}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const saveGlobalMeasurements = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SAVE_GLOBAL_MEASUREMENT}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const getMeasurementsInThreed = (taskId, user, measurements) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          measurements,
          "blobContainer": user.container,
          "sasToken": user.st,
          "taskId": taskId
        }),
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${GET_MEASUREMENTS_IN_THREED}?teamId=${await getActiveTeamId()}`, options);
      if (res.status == 200) {
        res.json().then((data => {
          resolve(data)
        }))
      } else {
        resolve({ measurements: [] })
      }
    }
    catch (e) {
      console.log(e)
      window.alert("Something went wrong while getting measurements.")
      reject()
    }
  })
};

export const getMeasurements = async (taskId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_MEASUREMENTS}/${taskId}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const getGlobalMeasurements = async (planId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    // body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_GLOBAL_MEASUREMENT}/${planId}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};


export const updateMeasurement = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  pushMeasurementToSocketRoom(data, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_UPDATED)
  const res = await fetch(`${UPDATE_MEASUREMENT}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const updateGlobalMeasurement = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_GLOBAL_MEASUREMENT}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const deleteMeasurement = async (measurement) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  pushMeasurementToSocketRoom(measurement, SOCKET_IO_MESSAGES.DRONENAKSHA.MEASUREMENT_DELETED)
  const res = await fetch(`${DELETE_MEASUREMENT}/${measurement.id}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};

export const deleteGlobalMeasurement = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_GLOBAL_MEASUREMENT}/${id}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
};
//measurements ends

// dowload zip in droneNaksha

export const getZipFIle = async (task_id, task_name, highRes) => {
  window.open(`${GET_ZIP_FILE}/${task_id}/${task_name}?teamId=${await getActiveTeamId()}&${highRes ? 'high=true' : ""}`, '_blank');
};

// download zip end 

//download CSV template
export const downloadCSVTemplate = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      fetch(`${DOWNLOAD_CSV_TEMPLATE}?teamId=${await getActiveTeamId()}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `GCP_CSV_Templates.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))

    }
    catch (error) {
      reject(error)
    }
  })
};

export const isUserProjectOwner = async (projectId, collectionId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${IS_USER_PROJECT_OWNER}/${projectId}?teamId=${await getActiveTeamId()}${collectionId ? `${`&collection_id=${collectionId}`}` : ``}`,
      options
    );
    if (res.status === 200) return await res.json();
    // if (res.status === 200) return Promise.resolve({isShared:true,access_level:"DOWNLOAD",sharedBy:{first_name:"Aakash"}});
    else return Promise.reject("failed to load");
  }
  catch {
    return Promise.reject("failed to load")
  }
};

export const acceptInvite = async (inviteId) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer a0caa289-2863-4f70-9907-367b6dc084d6`,
    },
  };
  try {
    const res = await fetch(
      `${ACCEPT_INVITE}/${inviteId}`,
      options
    );
    if (res.status === 500) return Promise.reject("Failed to Authenticate");
    else return await res.json();
  }
  catch {
    return Promise.reject("Failed to Authenticate")
  }
};

//IMPORT_MAPS_INTO_COLLECTION
export const importMapsIntoCollection = (maps, collectionId) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify({
        maps,
        collectionId,
      }),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(`${IMPORT_MAPS_INTO_COLLECTION}?teamId=${await getActiveTeamId()}`, options);
      if (res.status === 200) res.json().then(data => {
        resolve(data)
      })
      else reject("Something went wrong while importing maps.")
    } catch (e) {
      console.log(e)
      reject("Something went wrong while importing maps.")
    }
  })
};

// API for global search feature
export const globalSearch = async (searchString, filters) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      searchString: searchString,
      filters: filters,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GLOBAL_SEARCH, options);
  return await res.json();
};

//GET recent Ortho images
export const getRecentMaps = async (type, id, limit, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_RECENT_MAPS}/${type}/${id}/${limit}?service=${service}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

//GET all Ortho images
export const getAllMaps = async (searchString, start, pagesize, type, id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_MAPS}/${searchString}/${start}/${pagesize}?type=${type}&id=${id}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


//User statistics API 
export const getUserStatistics = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USAGE_STATISTICS}/${fromDate}/${toDate}`, options);
  return await res.json();
};

// Check all images for processing
export const checkImagesForProcessing = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${CHECK_INVALID_IMAGES}/${id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


// Downlaod GCP CSV file
//download CSV template
export const downloadGCPCSV = async (collection_id, collection_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      fetch(`${DOWNLOAD_GCP_CSV}/${collection_id}?teamId=${await getActiveTeamId()}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `${collection_name}_GCP.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    }
    catch (error) {
      reject(error)
    }
  })
};
// gcp error

export const GetGCPError = async (taskId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_GCP_ERROR}/${taskId}`, options);
  return await res.json();
};

//gcp error end

//get volume API

export const getVolume = async (calculationMethod, blobContainer, sasToken, taskId, polygon) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      blobContainer, sasToken, taskId, polygon, calculationMethod
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${tilesServer}/getVolume?teamId=${await getActiveTeamId()}`,
      options
    );
    return await res.json();
  }
  catch (e) {
    console.log(e)
    return new Promise.reject({
      error: "Something went Wrong"
    })
  }
};

//get volume API END

//copy images
export const exportImagesToNewCollection = async (new_collection_id, images, gcpData, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      new_collection_id,
      service,
      images,
      "gcpData": gcpData ? gcpData : [],
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${EXPORT_IMAGES_WITH_GCP}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};


//Get all annotation sets of a plan
export const getAnnotationSets = async (
  id, type = "plan"
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ANNOTATIONSETS}/${id}?type=${type}`,
    options
  );
  return await res.json();
};

//Get single annotation set
export const getAnnotationSet = async (
  id,
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ANNOTATIONSET}/${id}`,
    options
  );
  return await res.json();
};

//Get all detection models of a plan
export const getDetectionModels = async (
  plan_id,
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_MODELS}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};
// Add annotation set
export const addAnnotationSet = async (
  image_collection_id,
  annotation_set_name,
  classes,
  plan_id,
  task_id
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "image_collection_id": image_collection_id,
      "annotation_set_name": annotation_set_name,
      "classes": classes,
      "plan_id": plan_id,
      "task_id": task_id
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_ANNOTATIONSET}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

//delete annotation set
export const deleteAnnotationSet = async (imageCollectionId, annotationSetId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_ANNOTATIONSET}/${imageCollectionId}/${annotationSetId}`, options);
};

// delete all Annotations from image and file from BLOB
export const clearAllAnnotations = async (collection_id, annotation_set_id, file_name, classes, task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    }, body: JSON.stringify({ classes })
  };
  const res = await fetch(`${CLEAR_ANNOTATIONS}/${collection_id}/${annotation_set_id}/${file_name}?teamId=${await getActiveTeamId()}${task_id ? `&task_id=${task_id}` : ""}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};
// Get images from annotation set
export const getAnnotationSetImages = async (
  collection_id,
  annotationSet_id,
  pageSize,
  pageNumber,
  task_id,
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ANNOTATION_SET_IMAGES}/${collection_id}/${annotationSet_id}/${pageSize}/${pageNumber}?teamId=${await getActiveTeamId()}${task_id ? `&task_id=${task_id}` : ""}`,
    options
  );
  return await res.json();
};

/* Edit task */

export const editTask = async (id, task) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(task),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_TASKS + "/" + id}`, options);
  return res
};


//Add user analytic record 
export const addUserAnalytic = async (pageName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_USER_ANALYTIC + "/" + pageName}`, options);
  return res.json()
};

//Update out time of a page

export const updateUserAnalytic = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_PAGE_ANALYTICS + "/" + id}`, options);
  return res
};

//Get user analytics 
export const getUserAnalytics = async (userId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_ANALYTICS + "/" + userId}`, options);
  return await res.json();
};

//Get user page analytics 
export const getUserPageAnalytics = async (userId, pageName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_PAGE_ANALYTICS + "/" + userId + "/" + pageName}`, options);
  return await res.json();
};

// Survey API
export const addUserSurvey = async (industry, role, teamSize, phone, country) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      industry,
      role,
      teamSize,
      phone,
      country
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_SURVEY_DETAILS}`, options);
  return await res.json();
};

// Survey API
export const checkSurveyStatus = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHECK_SURVEY_STATUS}`, options);
  return await res.json();
};

//Download user survey data CSV

export const downloadSurveyRecords = async (searchString, sortBy, sort, status, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    try {
      let sorting = sortBy && sort ? `&sortBy=${sortBy}&sort=${sort}` : ''
      let queryStr = `?status=${status}&fromDate=${fromDate}&toDate=${toDate}${sorting}`
      fetch(`${DOWNLOAD_SURVEY_RECORDS}/${encodeURIComponent(searchString)}/${queryStr}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `UserSurveyRecords.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    }
    catch (error) {
      reject(error)
    }
  })
};

//Get user signup statistics 

export const getUserSignupStatistics = async (industry, country, role, fromDate, toDate, graphUnit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_STATISTICS}/${industry}/${country}/${role}/${fromDate}/${toDate}/${graphUnit}`, options);
  return await res.json();
};

//Export annotation file of single image

export const exportAnnotation = async (annotatedData) => {
  return new Promise((resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(annotatedData)
    };

    try {
      fetch(`${EXPORT_ANNOTATION_FILE}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            let temp = annotatedData.fileName.split('.')
            link.download = `${temp[0]}.json`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

//Export all annotation data as zip file.

export const exportAnnotationData = async (annotatedData) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(annotatedData)
    };

    try {
      fetch(`${EXPORT_ANNOTATION_DATA}?teamId=${await getActiveTeamId()}`, options)
        .then(response => response.status == "200" ?
          resolve(response) : response.status == "202" ? reject({ code: 202, message: "No images are annotated in this dataset." }) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

//Export all annotation data as zip file.

export const exportDetectionData = async (annotatedData) => {
  return new Promise((resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(annotatedData)
    };

    try {
      fetch(`${EXPORT_DETECTION_FILE}`, options)
        .then(response => response.status == "200" ?
          resolve(response) : response.status == "202" ? reject({ code: 202, message: "No task found/ Invalid task." }) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

//Export all annotation data as zip file.

export const exportDetectionZip = async (taskId) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    try {
      fetch(`${EXPORT_DETECTION_DATA}/${taskId}?teamId=${await getActiveTeamId()}`, options)
        .then(async (response) => response.status == "200" ?
          resolve(response) : response.status == "202" ? reject(await response.json()) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

//Get picstork dashboard statistics data

export const getGraphData = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_GRAPH_DATA}`,
    options
  );
  return await res.json();
}


export const getStatData = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_STAT_DATA}`,
    options
  );
  return await res.json();
}

export const saveYieldAnalysis = async (stats, measurement_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      stats, measurement_id
    })
  };
  const res = await fetch(`${SAVE_YIELD_ANALYSIS}`, options)
  if (res.status !== 201) { }
}
export const getYieldAnalysis = (taskId, blobContainer, sasToken, geoJSON) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        geoJSON, taskId, blobContainer, sasToken
      })
    };
    //get from tiles server
    const res1 = await fetch(
      `${tilesServer}/getYieldAnalysis`,
      options
    );
    if (res1.status === 200) res1.json().then(YA => resolve(YA));
    else reject({
      message: "somthing went wrong..."
    });
  })
}

//Detector APIs

export const getDetectors = async (type, pageSize, startIndex, searchString) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_USER_DETECTORS}/${type}/${searchString}/${pageSize}/${startIndex}`,
    options
  );
  return await res.json();
}

export const deleteDetector = async (type, detector_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_DETECTOR}/${type}/${detector_id}`,
    options
  );
  return await res.json();
}

//Duplicate annotation set 

export const duplicateAnnotationSet = async (id, setName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DUPLICATE_ANNOTATION_SET}/${id}/${setName}`,
    options
  );
  return await res;
}


//Get user signupdata 
export const getUserSingupData = async (industry, country, role, fromDate, toDate, pageSize, startIndex) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_USER_SIGNUP_DATA}/${industry}/${country}/${role}/${fromDate}/${toDate}/${pageSize}/${startIndex}`,
    options
  );
  return await res.json();
}

//Download user signup data CSV

export const downloadUserRecords = async (industry, country, role, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    try {
      fetch(`${DOWNLOAD_SIGNUP_RECORDS}/${industry}/${country}/${role}/${fromDate}/${toDate}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `UserSignupData.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    }
    catch (error) {
      reject(error)
    }
  })
};

// Upload Detector ZIP 
export const uploadPublicDetector = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPLOAD_PUBLIC_DETECTOR}`, options);
  return res
};


//Add Annotation report task API 

export const addAnnotationReportTask = (task) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = await response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify(task),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(`${ADD_ANNOTATION_REPORT_TASK}?teamId=${await getActiveTeamId()}`, options);
    if (res.status === 200) {
      res.json().then(data => {
        resolve(data)
      })
    } else {
      res.json().then(data => {
        reject({ message: data.message || "Something went wrong..." })
      })
    }
  })
};

export const getLineStatistics = (taskId, blobContainer, sas, latLngs) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ taskId, sas, blobContainer, latLngs })
    };

    try {
      fetch(`${tilesServer}/getPolylineStats?teamId=${await getActiveTeamId()}`, options)
        .then(response => response.status == "200" ?
          resolve(response.json()) : response.status == "202" ? reject({ message: "Elevation is not available." }) : reject({ message: "Something went wrong while getting line measurements." }))

    } catch (error) {
      reject({ message: "Something went wrong while getting line measurements." })
    }
  })
}

export const getElevationOfPoint = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${tilesServer}/getElevation/${data.task_id}/${data.lat}/${data.lng}?sas=${encodeURIComponent(data.sas_token)}&blobContainer=${data.blob_container}&viewDefault=true&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}
export const getPlantHealthIndexOfPoint = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${tilesServer}/getPlantHealthIndex/${data.task_id}/${encodeURIComponent(data.algorithm)}/${data.lat}/${data.lng}?startRange=${encodeURIComponent(data.min)}&endRange=${encodeURIComponent(data.max)}&sas=${encodeURIComponent(data.sas_token)}&blobContainer=${data.blob_container}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


//Elevation Profile
export const getElevationProfileData = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      taskId: data.taskId,
      blobContainer: data.blobContainer,
      sas: data.sas,
      latLngs: data.latLngs
    })
  };
  const res = await fetch(
    `${tilesServer}/getElevationProfile?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};


export const downloadDetectorZip = async (detector_id) => {
  return new Promise((resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    try {
      fetch(`${DOWNLOAD_DETECTOR_ZIP}/${detector_id}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `${detector_id}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

export const loginUser = (username, password, clientName) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ username, password })
    };

    try {
      fetch(`${LOGIN_USER}?clientName=${clientName}`, options)
        .then(response => response.status == "200" ?
          resolve(response.json()) : reject(response.json()))

    } catch (error) {

      reject({ message: "Something went wrong while logging." })
    }
  })
}


export const signUp = (user) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user, registrationType: user.registrationType })
    };

    try {
      fetch(`${SIGNUP}`, options)
        .then(response => response.status == "201" ?
          resolve(response.json()) : reject(response.json()))

    } catch (error) {

      reject({ message: "Something went wrong while logging." })
    }
  })
}

export const resetPassoword = (email) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
      },
      body: JSON.stringify({ email })
    };

    try {
      fetch(`${RESET_PASSWORD}`, options)
        .then(response => response.status == "200" ?
          resolve(response.json()) : reject(response.json()))
    } catch (error) {
      reject({ message: "Something went wrong while password reset." })
    }
  })
}

export const sendUserAuthOtp = async (username, password, sendOTPOnMobile = false, sendOTP) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
    body: JSON.stringify({
      username: username, password: password, sendOTPOnMobile, sendOTP
    })
  };
  const res = await fetch(
    `${USER_AUTH_OTP}`,
    options
  );
  return res
};

export const changePhoneNumberReqOTP = async (phone) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      phone
    })
  };
  const res = await fetch(
    `${CHANGE_PHONE_NUMBER_REQ_OTP}`,
    options
  );
  return res
};


export const verifyOtp = async (username, password, otp) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
    body: JSON.stringify({
      username: username, password: password, otp: otp
    })
  };
  const res = await fetch(
    `${USER_VERIFY_OTP}`,
    options
  );
  return res
};

export const updateNumberWithOTP = async (phone, otp) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      phone, otp
    })
  };
  const res = await fetch(
    `${UPDATE_NUMBER_WITH_OTP}`,
    options
  );
  return res
};



export const getOtpTimer = async (username) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
  };
  const res = await fetch(
    `${GET_OTP_TIMER}?username=${username}`,
    options
  );
  return res
};

export const updateTwoStepAuth = async (flag) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_TWO_STEP_AUTH}/${flag}`, options);
  if (res.status === 202 || res.status === 200) return await res.json();
  else throw (await res.json())
}

export const generateOrthoDefectPdf = async (data, task_name, pdfData, pieData) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data, task_name, pdfData, pieData, serverURL: SERVER })
  };

  const res = await fetch(
    `${GENERATE_ORTHO_DEFECT_PDF}?teamId=${await getActiveTeamId()}`,
    options
  );
  return res
}

export const generateDefectPdf = async (data, task_name, pdfData, pieData) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data, task_name, pdfData, pieData })
  };

  const res = await fetch(
    `${GENERATE_DEFECT_PDF}?teamId=${await getActiveTeamId()}`,
    options
  );
  return res
};
export const generateThermalPdf = async (data, task_name, pdfData, pieData) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data, task_name, pdfData, pieData })
  };

  const res = await fetch(
    `${GENERATE_THERMAL_PDF}?teamId=${await getActiveTeamId()}`,
    options
  );
  return res
};


export const generateDetectionPdf = async (viewData, pdfData, pieData, colors, imgData) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ viewData, pdfData, pieData, colors, imgData })
  };

  const res = await fetch(
    `${GENERATE_DETECTION_PDF}`,
    options
  );
  return res
};

export const downloadPdfFile = async (task_id, type, col_id, taskType, filetype) => {
  return new Promise(async (resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      fetch(`${DOWNLOAD_PDF_FILE}/${task_id}/${type}/${col_id}/${taskType}/${filetype}?teamId=${await getActiveTeamId()}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `${type}_report.${filetype}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : response.status == '202' ? resolve(202) : reject("File not found"))

    } catch (error) {
      reject(error)
    }
  })
};

export const getCountryData = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_COUNTRY_COUNT}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const getCustomStats = async (type, value, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_CUSTOM_STATS}/${type}/${value}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

//to update picstork task details 

export const updateTaskDetails = async (task_id, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...data, isFromWeb: true })
  };
  const res = await fetch(
    `${UPDATE_TASK_DETAILS}/${task_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const getDsmImages = async (collection_id, searchString) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_DSM_IMAGES}/${collection_id}?searchString=${encodeURIComponent(searchString)}`,
    options
  );
  return await res.json();
}


export const getDetectorDetails = async (task_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_DETECTOR_DETAILS}/${task_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const getIndustryData = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_INDUSTRY_DATA}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const uploadImages = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/AeroCapture/image/uploadImage?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

export const getFloorImages = (id) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      // body: data,
      headers: {
        "Allow-Control-Access-Origin": URL,
        authorization: `Bearer ${token}`,
      },
    };
    const url = SERVER + `/AeroMegh/AeroFarm/floors/data/${id}?teamId=${await getActiveTeamId()}`;
    const res = await fetch(url, options);
    if (res.status === 200) res.json().then(data => resolve(data))
    else reject({ errorMessage: "Something went wrong." })
  })
}

export const deleteFloorImg = async (data, imageType, planId, floorId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/AeroFarm/floorsImages/${floorId}/${planId}/${imageType}?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return await res.json()
}



//replace utm geojson with latlng geojson
export const replaceCADUTM = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/replaceCADUTM?teamId=${await getActiveTeamId()}`;
  const res = await axios.post(url, data, options)
  return res
}
//save shapeFile
export const saveMapAssets = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/saveMapAssets?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

//update layer
export const updateLayer = async (layer) => {


  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "POST",
        body: JSON.stringify(layer),
        headers: {
          "Allow-Control-Access-Origin": URL,
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },

      };
      const url = SERVER + `/AeroMegh/AeroFarm/layers/updateLayer?teamId=${await getActiveTeamId()}`;
      try {
        const res = await fetch(url, options);
        if (res.status === 200) resolve((await res.json()).updatedLayer)
        else reject(await res.json())
      } catch (err) {
        reject({ message: "Something went wrong while updating layer" })
      }
    })
  }
}

// import Orthomosaic
export const importOrthomosaic = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadOrtho?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}
// import las laz
export const importLazLas = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadLazLas?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}
// Import DSM
export const importDSM = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadDem?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}
// Import DTM
export const importDTM = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadDtm?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}
// Import 3D
export const import3D = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },

  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadThreeD?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

export const importPntCld = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/AeroFarm/image/uploadPointCloud?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

export const exportShapeFile = async (geoJson) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify({
        geoJson
      }),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${tilesServer}/picStork/getShape`,
        options
      );
      if (res.status === 200) res.blob().then(blob => {
        resolve(blob)
      })
      else throw { message: "Somothing wend wrong" }

    }
    catch (e) {
      reject({
        error: "Something went Wrong"
      })
    }
  })
}


export const donwloadCollectionZip = async (collectionId, collectionName) => {
  window.open(`${DOWNLOAD_COLLECTION_ZIP}/${collectionId}/${collectionName}.zip?teamId=${await getActiveTeamId()}`, '_blank');
};

export const copyTaskCollection = async (taskId, colId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${COPY_TASK_COLLECTION}/${taskId}/${colId}?teamId=${await getActiveTeamId()}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200)
      resolve(await res.json());
    else reject(await res.json())
  })
};


export const renameProject = async (pid, name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${RENAME_PROJECT}/${pid}/${name}`, options);
  return res;
}

// Planner API's
export const assignTaskToTM = async (data) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${ASSIGN_TASK_TO_TM}`,
        options
      );
      if (res.status === 200) res.json().then(data => {
        resolve(data)
      })
      else throw { message: "Somothing went wrong" }

    }
    catch (e) {
      reject({
        error: "Something went Wrong"
      })
    }
  })
};

export const getAssignedTasksAPI = async (teamId = false, project_id = false, limit) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${GET_ASSIGNED_TASKS}/${teamId}/${project_id}${limit ? `?limit=${limit}` : ''}`,
        options
      );
      if (res.status === 200) res.json().then(data => {
        resolve(data)
      })
      else throw { message: "Somothing went wrong" }

    }
    catch (e) {
      reject({
        error: "Something went Wrong"
      })
    }
  })
};

export const updateAssignedTask = async (data) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${UPDATE_ASSIGNED_TASKS}`,
        options
      );
      if (res.status === 201) res.json().then(data => {
        resolve(data)
      })
      else throw { message: "Somothing went wrong" }

    }
    catch (e) {
      reject({
        error: "Something went Wrong"
      })
    }
  })
}
export const deleteAssignedTask = async (taskId) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {

      const res = await fetch(
        `${DELETE_ASSIGNED_TASKS}/${taskId}`,
        options
      );
      if (res.status === 200) res.json().then(data => {
        resolve(data)
      })
      else throw { message: "Somothing went wrong" }

    } catch (e) {
      reject({
        error: "Something went Wrong while deleting task"
      })
    }
  })
}
export const addCompareMeasurements = async (measurement_id, plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "PUT",
    body: JSON.stringify({ measurement_id, plan_id }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${COMPARE_MEASUREMENT}?teamId=${await getActiveTeamId()}`, options);
  return res.json();
}

export const getMeasurementComparison = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_MEASUREMENT_COMPARISON}/${plan_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}


export const getWindMillImageData = async (image_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_WINDMILL_IMAGE}/${image_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const saveWindMillData = async (data, image_id) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${SAVE_WINDMILL_ANNOTATION}/${image_id}?teamId=${await getActiveTeamId()}`,
        options
      );
      if (res.status === 201) res.json().then(data => {
        resolve(data)
      })
      else throw { message: "Somothing went wrong" }

    }
    catch (e) {
      reject({
        error: "Something went Wrong"
      })
    }
  })
};


// Import DSM
export const importZipImages = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/uploadZipImages?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

// project compare stat API 
export const getProjectStats = async (projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_PROJECT_STATE}/${projectId}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const getNotifications = async (limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_NOTIFICATIONS}/${await getActiveTeamId()}${limit ? `?limit=${limit}` : ''}`,
    options
  );
  return await res.json();
}

export const addNotification = async (receiver, content, props, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "receiver": receiver,
      "content": content,
      "props": props,
      "type": type,
      "teamId": await getActiveTeamId()
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${ADD_NOTIFICATION}`,
    options
  );
  return await res.json();
}

//Floor Apis
export const addFloor = async (floor) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(floor),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_FLOOR}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const getAllFloors = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ALL_FLOORS}/${plan_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}
export const deleteFloor = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_FLOOR}/${id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}


export const getFloorIssues = async (image_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${FLOOR_ISSUES}/${image_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();

}

export const addFloorIssue = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${FLOOR_ISSUES}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();

}

export const updateFloorIssue = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${FLOOR_ISSUES}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();

}


export const deleteFloorIssue = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${FLOOR_ISSUES}/${id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}


export const saveGoogleDriveData = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${`${SERVER}/Aeromegh/googleDrive`}?teamId=${await getActiveTeamId()}`,
    options
  );
  return new Promise(async (resolve, reject) => {
    if (res.status === 200)
      resolve(await res.json());
    else reject()
  })
}


export const getStorageUserByService = (serviceName) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `${GET_STORAGE_BY_SERVICES}/${serviceName}?teamId=${await getActiveTeamId()}`,
      options
    );
    if (res.status === 200) res.json().then(data => {
      resolve(data)
    })
    else {
      resolve({ error: "Something went wrong while getting storage usage." })
    }
  })
}

export const getTaskCreditsDetails = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_CREDIT_DETAILS}`, options);
  return await res.json();
}

export const importProjectFromService = async (projectId, fromService, toService) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ projectId: projectId, fromService: fromService, toService: toService }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/importProjectFromToservice`, options);
  if (res.status === 200) return await res.json();
  else if (res.status === 400) return ({
    error: "Project is already exist with same name."
  })
  else return await res.json();
};
// Get task credit plans
export const fetchCreditPlans = async (creditType) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(`${GET_TASK_CREDIT_PLANS}?type=${creditType}`, options);
    if (res.status === 200) {
      return res.json();
    } else {
      throw new Error("Error getting credit plans");
    }
  } catch (error) {
    throw error;
  }
}

export const getUserCreditInvoices = async (pageSize, pageNumber) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_CREDITS_PAYMENT_DETAILS}/${pageSize}/${pageNumber}`, options);
  if (res.status === 200) return await res.json();
  else if (res.status === 400) return ({
    error: "Something went wrong while getting user credit invoices."
  })
}

export const getUserCreditDetailsByAdmin = async (userid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_CREDITS_DETAILS_ADMIN}?userid=${userid}`, options);

  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject({
      error: "Something went wrong while getting user credit details."
    })
  })
}

export const getUserStorage = async (userid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${GET_USER_STORAGE}${userid ? `?userid=${userid}` : ""}`, options);

  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject({
      error: "Something went wrong while getting user storage details."
    })
  })
}

export const updateUserCreditDetailsByAdmin = async (userid, updatedCreditDetails) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updatedCreditDetails)
  };
  const res = await fetch(`${UPDATE_USER_CREDITS_DETAILS_ADMIN}?userid=${userid}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject({
      error: "Something went wrong while updating user credit details."
    })
  })
}
export const getPendingPayments = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getPendingPayments`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const getUserDroneLimits = async (service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getUserDroneLimits?service=${service}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const getUserSubscriptionDiscount = async (coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getUserSubscriptionDiscount/${coupon_id}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const addDiscountCoupon = async (user_id, discountCoupon) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/adminTool/addDiscountCouponByAdmin/${discountCoupon.discount}?userid=${user_id}&coupon_name=${discountCoupon?.coupon_name}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const getAllDiscountCoupons = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/getAllDiscountCoupons`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const deleteDiscountCoupon = async (coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/deleteDiscountCoupon/${coupon_id}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}
export const editDiscountCoupon = async (discountCoupon) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(discountCoupon)
  };
  const res = await fetch(`${SERVER}/editDiscountCoupon`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getAnalytics = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };

      const res = await fetch(`${GET_ANALYTICS}`, options);
      res.json().then(result => {
        resolve(result);
      }).catch(err => {
        reject({ "Error ": err })
      })
    })
  }
};


// detection region APIs

export const addDetectionsRegions = async (task, imageDetails, regions) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, regions })
      };

      const res = await fetch(`${ADD_DETECTIONS_REGION}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 201) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while adding region."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while adding region." })
      })
    })
  }
};


export const deleteDetectionsRegion = async (task, imageDetails, className, regionId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "DELETE",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, className, regionId })
      };

      const res = await fetch(`${DELETE_DETECTIONS_REGION}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while deleting region."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while deleting region." })
      })
    })
  }
};

export const clearAllDetections = async (task, imageDetails) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "DELETE",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails })
      };

      const res = await fetch(`${CLEAR_ALL_REGIONS_FROM_DETECTIONS}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while clearing all regions."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while clearing all regions." })
      })
    })
  }
}
export const updateDetectionsRegion = async (task, imageDetails, region) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "PUT",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, region })
      };

      const res = await fetch(`${UPDATE_DETECTIONS_REGION}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while updating region."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while updating region." })
      })
    })
  }
};

export const updateDetectionsRegionsOfClass = async (task, imageDetails, regions) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "PUT",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, regions })
      };

      const res = await fetch(`${UPDATE_DETECTIONS_REGIONS_OF_CLASS}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while updating region."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while updating region." })
      })
    })
  }
};

export const addDetectionClassName = async (task, imageDetails, className) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, className })
      };

      const res = await fetch(`${ADD_DETECTIONS_CLASSNAME}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while adding classname."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while adding classname." })
      })
    })
  }
};


export const changeDetectionClassName = async (task, imageDetails, region, oldClass, newClass) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "PUT",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task, imageDetails, region, oldClass, newClass })
      };

      const res = await fetch(`${CHANGE_DETECTIONS_CLASSNAME}?teamId=${await getActiveTeamId()}`, options);

      res.json().then(result => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject({
            message: result.message || "Something went wrong while changing classname."
          })
        }
      }).catch(err => {
        reject({ message: err.message || "Something went wrong while changing classname." })
      })
    })
  }
};



export const getPicstorkMeasure = async (data) => {
  return new Promise(async (resolve, reject) => {

    try {
      if (!SESSION_ID_CHANGED) {
        SESSION_ID_CHANGED = true;
        response = await sessionCheck();
      }
      token = response.access_token;
      const options = {
        method: "POST",
        headers: {
          "Allow-Control-Access-Origin": URL,
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      };
      const r = await fetch(`${tilesServer}/getPicstorkMeasure`, options);

      const res = await r.json();
      if (res.detail) {
        console.log(res.detail)
        Popup.alert("ERROR", "Something went wrong while calculating measurements");
        return reject()
      }
      else return resolve(res.result)
    } catch (err) {
      console.log(err)
      Popup.alert("ERROR", "Something went wrong while calculating measurements");
      reject()
    }
  })

}